/*  ===== MOBILE styles (reside at the top and are inherited by all devices) ===== */
/*  ===== See tablet, desktop, and print @media queries at the bottom. */


/*   ----- Imports  -----  */
  /* @import 'node_modules/modern-normalize/modern-normalize.css';  npm install modern-normalize  Resets elements for browser consistency.  https://www.npmjs.com/package/modern-normalize */         
  /* @import "component1.css";   Component-specific stylesheets, if any.   */
  /* @import url(//fonts.googleapis.com/css?family=Font+Name); Add fonts from a hosted services like Google Fonts.   */

 
  @import url('https://fonts.googleapis.com/css?family=Inter:400,600,700,800,900');
  
  @import "~react-image-gallery/styles/css/image-gallery.css";
  
  /*   ----- Variables  -----  */
  :root {
      --custom-color-dark: #233963;
      --custom-color-medium: #ae8057;
      --custom-color-light: #f1e7da;
      --custom-color-contrast:	#d3cdc4;
      --custom-color-transparency: rgba(113, 96, 89, 0.5);
      --custom-embellishment-name:;
    }
    
    /*  ----- Viewport ----- elements inherited by all remaining elements ----- */
    body {       /* Can alternatively define in html {}. */
      background-color:transparent;
      background-image: url('images/pexels.jpg');
      background-size: cover;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      color: var(--custom-color-contrast);
      font-family: 'Inter', Helvetica, sans-serif;
      margin:0 auto;
      padding:0;
      max-width: 1200px;
    }
    
    /*  ----- Page layout blocks -----  */
    header {
      background-color: var(--custom-color-transparency);
      color: var(--custom-color-light);
      padding: 10px;
      margin: 10px;
      border-bottom: 3px solid var(--custom-color-medium);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  header img {
      width: 10%;
      height: auto;
      margin: 0 10px;
  }
    main {
      background-color: var(--custom-color-light);
      color: var(--custom-color-dark);
      padding: 15px;
      margin: 15px;
    }
    section {}
    article {}
      #topic1 {}
      #topic2 {}
    div {}
    aside {}
    footer {
      background-color: var(--custom-color-medium);
      color: var(--custom-color-light);
      padding: 10px;
      border-top: 1px solid var(--custom-color-dark);
    }
    footer p {
      text-align: center;
      margin: 0;
      color: var(--custom-color-contrast);
    }
    /* ----- Anchors ----- Text links */
    a {}
    a:link {}
    a:hover {}
    a:active {}
    a:visited {}
    
    /*  ----- Navigation ----- Override text links for navigation only.  */
    nav {
      display: flex;
      justify-content: center;
      background-color: var(--custom-color-medium);
      padding: 5px;
      margin: 5px;
      border: 3px solid var(--custom-color-dark);
      border-radius: 0;
      flex-wrap: wrap;
    }
    nav a {
      display: inline-block;
      text-decoration: none;
      color: var(--custom-color-light);
      background-color: var(--custom-color-contrast);
      padding: 5px;
      margin: 5px;
      border: 3px solid var(--custom-color-dark);
      border-radius: 0;
    }
    nav ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
  
    nav li {
      margin-right: 10px;
      
    }
    nav a:hover {
      background-color: var(--custom-color-dark);
      color: var(--custom-color-light);
    }
  
    .local a {
      border: none;
      display: flex;
      border-radius: 0;
      background-color: var(--custom-color-medium); 
    }
    
    
    /*  -----  Typography  ----- */
    p {}
    br {}
    ol, ul, dl {}
    li {}
    dt {}
    dd {}
    
    h1, h2, h3, h4, h5, h6 {font-family: 'Inter', Helvetica, sans-serif} 
    h1 {}
    h2 {}
    h3 {}
    h4 {}
    h5 {}
    h6 {}
    
    abbr {}
    acronym {}
    address {}
    blockquote {}
      q {}
      cite {}
    em {}
    hr {}
    mark {}
    s {}
    span {}
    strong {}
    sub {}
    sup {}
    time {}
    
    /*  ----- Coding or instructional typography ----- */
    pre {}
    code {}
    kbd {}
    samp {}
    var {}
    del {} 
    ins {}
    
    /*  ----- Table ----- */
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 15px;
    }
    caption {
      background-color: var(--custom-color-medium);
      border: 1px solid var(--custom-color-dark);
      color: var(--custom-color-dark);
      font-weight: bold;
      padding: 1% 0;
    }
    thead {
      text-align:center;
      color: var(--custom-color-dark);
      font-weight: bold;
    }
    tbody {
      text-align:center;
      background-color: var(--custom-color-transparency);
      color: var(--custom-color-dark);
      
    }
    tfoot {}
    tr td {
      text-align: center;
      border: 1px solid var(--custom-color-dark);
    }
    tr th {
      background-color: var(--custom-color-transparency);
      padding: 2%;
      text-align: center;
      border: 1px solid var(--custom-color-dark);
    }
    colgroup {}
    data {}
    datalist {}
    
    
    /*  ----- Form ----- */
    form {
      background-color: var(--custom-color-light);
      padding: 15px;
      margin: 30px auto;
      border: 2px solid var(--custom-color-medium);
      display: flex;
      flex-direction: column;
      align-items:
    }
    fieldset {
      border: 1px solid var(--custom-color-medium);
      margin: 15px 0;
      padding: 15px;
    }
      legend {
        font-weight: bold;
      }
    label {
      display: block;
      margin: 10px 0;
      font-weight: bold;
    }
    button {
      padding: 10px 20px;
      background-color: var(--custom-color-contrast);
      color: var(--custom-color-light);
      border: none;
      border-radius: 5px;
    }
    button:hover {
      background-color: var(--custom-color-dark);
    }
    input, button, textarea, select {
      font-size:115%;
      padding:2%;
      font-family:inherit;
  }
  :focus{
    background-color: var(--custom-color-medium);
  }
  *:required {
    border:2px solid red;
  }
  
      optgroup {}
      option {}
  
    output {}
    meter {}
    progress {}
    
    
    /* ----- Media ----- */
    @media all and (max-width: 600px) {
      body {
        background-size: auto; /* Adjust background size for smaller screens */
      }
      nav ul {
        flex-direction: column; /* Stack nav items vertically on small screens */
      }
      .gallery {
        flex-direction: column; /* Adjust gallery layout for smaller screens */
      }
      header img {
        width: 50%; /* Adjust logo size for smaller screens */
      }
      form {
        flex-direction: column; /* Ensure form fields stack vertically */
        width: 90%; /* Adjust form width for smaller screens */
        margin: 15px auto; /* Center form on the page */
      }
    }
    
  
  
    svg {}
    picture {}
      source {}
    iframe {}
    video {}
    audio {}
    embed {}
    object {}
    
    /*  ----- Classes for alignment, positioning, widths, grids, embellishments, and animation -----  */
    .gallery {}
    
    .floatright {}
    .floatleft {}
    .center-middle {}
    
    .ten {}
    .twenty {}
    .thirty {}
    .forty {}
    .fifty {}
    .sixty {}
    .seventy {}
    .eighty {}
    
    .radius {}
    .circle {}
    .boxshadow {}
    .tshadow {} 
    .gradient {}
    .shape {}
    
    @keyframes App-logo {}
    
    /*   ===== TABLET  media query overrides mobile styles ===== */
    @media all and (min-width: 600px) {}
    
    /*   ===== DESKTOP  media query overrides mobile and tablet styles ===== */
    @media all and (min-width: 1080px) {}
    
    /*   ===== PRINT  media query overrides previous styles =====  */
    @media print {}
    